
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "company",
  });

  get caption() {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Компании",
        to: "/manage/companies/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  createItemModel: any = {
    companyId: +this.$route.params.id,
    caption: "",
  };

  companyBranchDataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([new Filter("companyId", +this.$route.params.id)]),
    },
    className: "companyBranch",
  });
}
